<template>
  <div class="main-box">
    <a-breadcrumb>
      <a-breadcrumb-item @click="$router.go(-1)"
        ><router-link to="/personalCenter/myCoupon"
          >我的优惠券</router-link
        ></a-breadcrumb-item
      >
      <a-breadcrumb-item style="color: #15b7dd">系统可使用商品</a-breadcrumb-item>
    </a-breadcrumb>
    <!-- 优惠券详情 -->
    <!-- <div class="couponStyle" v-if="couponDetail">
      <p class="price" style="font-size: 32px;" v-if="couponDetail.type == 1">¥<span>{{couponDetail.couponPrice}}</span></p>
      <p class="price" v-if="couponDetail.type == 3"><span>{{couponDetail.discount}}</span>折</p>
      <div class="detail">
        <div class="couponconditionTime">
          <p class="condition" v-if="couponDetail.type == 1">满{{couponDetail.fullPrice}}元减{{couponDetail.couponPrice}}</p>
          <p class="condition" v-if="couponDetail.type == 3">满{{couponDetail.discountPrice}}{{couponDetail.discountType==1?'件':'元'}}{{couponDetail.discount}}折</p>
          <p class="time" v-if="couponDetail.times == 2 || couponDetail.times == 3">{{couponDetail.startTime}}~{{couponDetail.lapseTime}}</p>
				  <p class="time" v-if="couponDetail.times == 1">不限使用时间</p>
        </div>
        <p class="type">部分使用：{{couponDetail.scope==1?'全场通用':couponDetail.scope==2?'适用'+productType(couponDetail.categoryType)+'商品':'适用指定商品'}}</p>
        <p class="rules">使用规则：限{{couponDetail.scope==1?'全场通用':couponDetail.scope==2?productType(couponDetail.categoryType)+'商品':'指定商品'}}使用，每笔订单只可使用一张优惠券</p>
      </div>
    </div> -->
    <!-- <div class="tabs">
      <p
        v-show="tabMenu.length > 1"
        class="tabItem"
        @click="tabChange(item.code)"
        :class="{ active: item.code === tabCode }"
        v-for="(item, index) in tabMenu"
        :key="index"
      >
        {{ item.name }}
      </p>
    </div> -->
    <div class="page">
      <div class="couponDetailStyle" v-if="couponDetail">
        <div class="condition">
          <div class="couponPrice"><span>{{ couponDetail.couponPrice }}</span><span>元</span></div>
          <div class="fullPrice">满{{couponDetail.fullPrice}}可用</div>
        </div>

        <div class="couponMess">
          <div class="name">
            <img src="@/assets/image/couponNameIcon.png" alt="">
            <p>{{ couponDetail.name }}</p>
          </div>
          <div class="detail">
            <p v-if="couponDetail.times == 2 || couponDetail.times == 3">有效期：{{couponDetail.startTime}}~{{couponDetail.lapseTime}}</p>
				    <p v-if="couponDetail.times == 1">有效期：不限使用时间</p>
            <p>使用规则：{{ couponDetail.receiveDesc }}</p>
          </div>
        </div>
      </div>
      <div class="contentList">
        <div class="contentItem" v-for="(item, index) in list" :key="index">
          <img class="prodPic" :src="item.listPhoto" alt="" />
          <div class="content">
            <p class="name" @click="routerPush(item)">{{ item.name }}</p>
            <div class="productType">商品类别 | {{item.type == 1?'课程':item.type == 2?'图书':item.type == 3?'教具':item.type == 4?'模拟考试':''}}</div>
            <div class="price">
              <p class="new">
                <span v-if="item.couponPrice"><span class="new_point">￥</span>{{managePrice(item.couponPrice)[0]}}<span class="new_point">.{{managePrice(item.couponPrice)[1]}}</span></span>
                <span class="free" v-else>免费</span>
              </p>
              <p class="old" v-if="item.original">
                ￥{{ item.originalPrice.toFixed(2) }}
              </p>
            </div>
            <div
              class="button2"
              @click="routerPush(item)"
            >
              查看详情
              <img src="@/assets/image/Group 5446.png" alt="" />
            </div>
          </div>
        </div>
        <a-spin class="spin" v-show="loading" />
      </div>
    </div>
    <Sidebar ref="sidebar"/>
  </div>
</template>

<script>
import Sidebar from "@/components/sidebar.vue";
export default {
  // 可用组件的哈希表
  components: { Sidebar },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      id: null, //父级id
      code: null, //父级code
      fontText: "", // 文本
      tabMenu: [], // 二级分类
      tabCode: null, // tabId
      pageNum: 1,
      pageSize: 10,
      total: 0,
      list: [],
      loading: false,
      couponDetail: null, // 优惠券详情数据
    };
  },
  // 事件处理器
  methods: {
    // 价格处理
    managePrice(con) {
      let txt = (con * 1).toFixed(2).split('.')
      return txt
    },
    font(e){
      return e.replace(/\<[^>]+>/g,'')
    },
    routerPush(e) {
      if (e.type == 1) {
        this.$router.push(
          "/knowledgeMall/courseDetail?productId=" +
            this.$AES.encode_data(e.productId + "") +
            "&code=" +
            e.code +
            '&name=' + this.$route.query.name
        );
      } else if (e.type == 2 || e.type == 3) {
        this.$router.push(
          "/knowledgeMall/curriculumDetail?productId=" +
            this.$AES.encode_data(e.productId + "") +
            '&name=' + this.$route.query.name
        );
      } else if (e.type == 4) {
        this.$router.push(
          "/knowledgeMall/examDetail?productId=" +
            this.$AES.encode_data(e.productId + "") +
            '&name=' + this.$route.query.name
        );
      }
    },
    // tab切换
    tabChange(e) {
      this.tabCode = e;
      this.list = [];
      this.productList.map(item=>{
        if(item.code == e){
          this.list = item.productList
        }
      })
    },
    // 分页
    onChange(pageNumber) {
      this.pageNum = pageNumber;
      this.list = [];
      this.getShopList();
    },
    // 获取二级分类
    // getChildType() {
    //   this.$ajax({
    //     url: "/hxclass-pc/pc-mall/pc/search",
    //     method: "get",
    //     params: {
    //       pId: this.id,
    //       code: this.code,
    //     },
    //   }).then((res) => {
    //     if (res.code == 200 && res.success) {
    //       this.tabMenu = res.data;
    //       if (!!this.$route.query.tabCode) {
    //         this.tabCode = this.$route.query.tabCode;
    //       } else {
    //         this.tabCode = res?.data[0].code;
    //       }
    //       this.getShopList();
    //     } else {
    //       this.$message.error(res.message);
    //     }
    //   });
    // },
    getShopList() {
      this.loading = true;
      this.$ajax({
        url: "/hxclass-pc/pc-mall/pc/list",
        method: "get",
        params: {
          categoryCode: this.tabCode, // 分类编码
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        },
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.list = res.data.records;
          this.total = res.data.total;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 获取优惠券详情
    getCouponDetail() {
      this.$ajax({
        url:'/hxclass-pc/pc-mall/available/product',
        method:'get',
        params: {
          couponId: this.couponId
        }
      }).then(res=>{
        if(res.code == 200 && res.success) {
          this.tabMenu = res.data.map(item=>{
            return {
              name:item.name,
              code:item.code
            }
          })
          // this.couponDetail = res.data;
          this.productList = res.data;
          // this.tabCode = res.data[0].code;
          this.list = res.data;
          // this.total = res.data.productList[0].productList.length;
        }
      })
    },
    // 商品类型转文字
    productType(e){
      let arr = e.split(',');
      let str = arr.map(item=>{
        if(item == 1){
          return '课程类'
        }else if(item == 2){
          return '图书类'
        }else if(item == 3){
          return '教具类'
        }else if(item == 4){
          return '模拟考试类'
        }
      })
      return str.join('、')
    }
  },
  // 生命周期-实例创建完成后调用
  created() {
    // if (this.$route.query.id && this.$route.query.code) {
    //   this.id = this.$AES.decode_data(this.$route.query.id);
    //   this.code = this.$route.query.code;
    //   this.getChildType(); // 获取二级分类
    // }
    if (this.$route.query.couponId) {
      this.couponId = this.$AES.decode_data(this.$route.query.couponId);
      this.getCouponDetail();
    }
    if(this.$route.params.couponDetail) {
      this.couponDetail = JSON.parse(JSON.stringify(this.$route.params.couponDetail));
      sessionStorage.setItem('couponDetail',JSON.stringify(this.couponDetail))
    }else{
      let couponDetail = JSON.parse(sessionStorage.getItem('couponDetail'))
      if(couponDetail) {
        this.couponDetail = couponDetail;
      }
    }
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  beforeDestroy() {
    // sessionStorage.removeItem('couponDetail')
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  // 监听,当路由发生变化的时候执行
  watch: {}
};
</script>

<style lang="less" scoped>
.page{
  margin-top: 32px;
  padding: 22px 12px 12px;
  background: #DAF8FF;
  border-radius: 20px 20px 20px 20px;
  .couponDetailStyle{
    padding-left: 36px;
    display: flex;
    align-items: center;
    .couponMess{
      margin-left: 22px;
      .name{
        display: flex;
        align-items: center;
        img{
          width: 17px;
          height: 17px;
          margin-right: 4px;
        }
        p{
          font-size: 18px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 27px;
        }
      }
      .detail{
        margin-top: 10px;
        display: flex;
        align-items: center;
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 18px;
        p:first-child{
          position: relative;
          margin-right: 41px;
        }
        p:first-child::after{
          content: '|';
          width: 1px;
          height: 18px;
          margin: 0 20px;
          position: absolute;
          top: 50%;
          right: -41px;
          transform: translateY(-55%);
        }
      }
    }
    .condition{
      padding: 7px 8px 10px;
      height: 73px;
      background-image: url('~@/assets/image/couponStyle.png');
      background-size: 100% 73px;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      .couponPrice{
        font-size: 26px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFF5EC;
        line-height: 39px;
        display: flex;
        span:nth-child(2){
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          display: inline-block;
          line-height: 25px;
          margin-top: 10px;
        }
      }
      .fullPrice{
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFF5EC;
        line-height: 18px;
      }
    }
    .condition::after{
      content: '';
      width: 6px;
      height: 51px;
      background: #95D5E4;
      position: absolute;
      right: -6px;
      bottom: 0;
    }
  }
}
.couponStyle{
  height: 124px;
  display: flex;
  align-items: center;
  padding-left: 21px;
  border: 1px solid #F7B7AF;
  border-radius: 5px;
  margin-top: 22px;
  background-color: #FFFBF7;
  background-image: url('~@/assets/image/knowledgeMall/couponDetailBack.png');
  background-size: 100%;
  .price{
    min-width: 109px;
    height: 109px;
    text-align: center;
    padding: 45px 20px 0;
    background-image: url('~@/assets/image/knowledgeMall/couponStyle.png');
    background-size: 100% 100%;
    font-size: 24px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    span{
      display: inline-block;
      font-size: 40px !important;
    }
  }
  .detail{
    flex: 1;
    padding-left: 32px;
    .couponconditionTime{
      display: flex;
      .condition{
        font-size: 24px;
        font-family: PingFang HK-Regular, PingFang HK;
        font-weight: 400;
        color: #333333;
        line-height: 36px;
      }
      .time{
        font-size: 14px;
        font-family: PingFang HK-Regular, PingFang HK;
        font-weight: 400;
        color: #666666;
        line-height: 36px;
        margin-left: 32px;
      }
    }
    .type{
      margin-top: 12px;
      font-size: 14px;
      font-family: PingFang HK-Regular, PingFang HK;
      font-weight: 400;
      color: #F07463;
      line-height: 21px;
    }
    .rules{
      margin-top: 8px;
      font-size: 14px;
      font-family: PingFang HK-Regular, PingFang HK;
      font-weight: 400;
      color: #666666;
      line-height: 21px;
    }
  }
}
.main-box {
  padding-top: 80px;
  padding-bottom: 60px;
  .tabs {
    display: inline-block;
    padding: 0 40px;
    margin-top: 22px;
    background-color: rgba(21, 183, 221, 0.1);
    .tabItem {
      display: inline-block;
      padding: 0 38px;
      line-height: 38px;
      font-size: 18px;
      color: #666666;
      margin-right: 20px;
      cursor: pointer;
    }
    .tabItem:last-child {
      margin: 0;
    }
    .active {
      color: #ffffff;
      background-color: #15b7dd;
    }
  }
  ::v-deep.ant-pagination {
    text-align: right;
    padding-right: 29px;
    padding-top: 45px;
  }
  .contentList {
    padding: 40px;
    border-radius: 5px;
    background: #ffffff;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .contentItem {
      width: 48%;
      background: #ffffff;
      border-radius: 5px;
      padding: 12px 39px 12px 12px;
      border: 1px solid rgba(0,0,0,0.2);
      transition: all 0.5s;
      display: flex;
      .prodPic {
        width: 110px;
        height: 110px;
        border-radius: 5px;
        transition: all 0.5s;
        background-repeat: no-repeat;
        object-fit: cover;
      }
      .content {
        margin-left: 12px;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        .name {
          cursor: pointer;
          font-size: 16px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 24px;
        }
        .productType{
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #15B7DD;
          line-height: 21px;
        }
        .navs {
          display: flex;
          // justify-content: space-between;
          align-items: center;
          height: 24px;
          overflow: hidden;
          flex-wrap: wrap;
          margin-top: 16px;
          word-wrap: break-word;
          word-break: normal;
          line-height: 21px;
          span {
            display: inline-block;
          }
          .type {
            font-size: 14px;
            font-family: PingFangMedium;
            color: #1e97b4;
            line-height: 21px;
            margin-right: 16px;
          }
          .nav {
            font-size: 12px;
            font-family: PingFangMedium;
            color: #EC6C01;
            line-height: 18px;
            border-radius: 4px;
            border: 1px solid #EC6C01;
            padding: 2px 8px;
            font-weight: 400;
            margin-right: 8px;
          }
          .teacherName {
            margin-top: 12px;
            font-size: 14px;
            font-family: PingFangMedium;
            color: #666666;
            line-height: 21px;
          }
          .slogan {
            margin-top: 26px;
            font-size: 18px;
            font-family: PingFangMedium;
            color: #333333;
            line-height: 24px;
          }
          .introduce {
            margin-top: 26px;
            font-size: 16px;
            font-family: PingFangMedium;
            font-weight: 400;
            color: #333333;
            line-height: 24px;
            position: relative;
            // display: flex;
            // flex-wrap: wrap;
            .backGround{
              padding-right: 24px;
              background-image: url(~@/assets/image/tag.png);
              background-size: 100% 100%;
            }
            span {
              white-space: nowrap;
              height: 21px;
              padding: 0 0 0 3px;
              text-align: center;
              display: inline-block;
              font-size: 14px;
              font-family: PingFangMedium;
              font-weight: 600;
              color: #ffffff;
              line-height: 24px;
              background-color: rgba(255, 120, 0, 0.8);
              > span {
                font-size: 20px;
                line-height: 21px;
                align-items: center;
              }
            }
            span:nth-child(2){
              margin-right: 20px;
            }
            .white {
              width: 0;
              height: 0;
              border-bottom: 24px solid white;
              border-left: 24px solid transparent;
              position: absolute;
              top: 3px;
              left: 95px;
            }
          }
        }
        .price {
          display: flex;
          align-items: center;
          margin-top: 19px;
          .new {
            font-size: 16px;
            font-family: PingFangMedium;
            color: #EC6C01;
            line-height: 21px;
            span {
              font-size: 28px;
            }
            .new_point {
              font-size: 16px;
            }
            .free {
              font-size: 24px;
            }
          }
          .old {
            font-size: 16px;
            font-family: PingFangMedium;
            font-weight: 400;
            text-decoration: line-through;
            color: #999999;
            line-height: 21px;
            margin-left: 9px;
          }
        }

        .button2 {
          text-align: left;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #15b7dd;
          cursor: pointer;
          line-height: 34px;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          img {
            width: 16px !important;
            height: 16px !important;
            margin-left: 8px;
          }
        }

        .button {
          width: 134px;
          height: 40px;
          background: #15b7dd;
          border-radius: 2px;
          margin-top: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          position: relative;
          overflow: hidden;
          cursor: pointer;
          &:hover::before {
            left: 100%;
          }
          p {
            margin-left: 5px;
          }
        }
        .button::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          left: -100%;
          background: linear-gradient(90deg, transparent, hsla(0, 0%, 100%, .5), transparent);
          transition: all .5s;
        }
      }
    }
    .contentItem:nth-child(n+3){
      margin-top: 24px;
    }
    .contentItem:hover {
      position: relative;
      z-index: 2;
      border-bottom: 1px solid #ffffff;
      box-shadow: 0px 4px 10px 1px rgba(93, 131, 167, 0.15);
      .prodPic{
        transform:scale(1.05,1.05)
      }
    }
    .spin {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
// 小屏分辨率内容区呈现1200px布局
@media screen and (max-width: 16000px) {
  .main-box .tabs {
    padding: 0 30px;
    .tabItem{
      padding: 0 25px;
    }
  }
}
</style>
